import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import {
  BasicInvitationFormFields,
  InvitationDTO,
  InvitationRenderDTO,
  RespondInvitationDTO,
  sendInvitationEmailResponse,
} from './types';
import { put, takeLatest } from '@redux-saga/core/effects';
import errorSlice from '../error/slice';
import { baseURL } from '../root-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import recruitmentSlice from './slice';
import { UploadFeedbackResponse } from '../submission/types';
import { getEmailRenderResponse, sendEmailResponse } from '../email/types';
import emailSlice from '../email/slice';
import infoSlice from '../info/slice';
import i18next from '../../i18n';

export const validateFile = async (file: File): Promise<Result<UploadFeedbackResponse, APIValidationError>> => {
  const formData = new FormData();
  formData.append('file', file);

  const init = {
    method: 'POST',
    auth: true,
    role: true,
    defaultContentType: true,
    body: formData,
  };

  return apiCall<UploadFeedbackResponse>(`${baseURL}/api/invitations/bulk/validate`, init);
};

export const validateInvitationFormFields = async (
  data: BasicInvitationFormFields,
): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/invitations/validate`, init);
};

export const getInvitationEmailRender = async (
  data: InvitationRenderDTO,
): Promise<Result<getEmailRenderResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<getEmailRenderResponse>(`${baseURL}/api/invitations/render`, init);
};

export const createInvitations = async (
  data: InvitationDTO,
): Promise<Result<sendInvitationEmailResponse, APIValidationError>> => {
  const { dry_run } = data;

  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<sendInvitationEmailResponse>(`${baseURL}/api/invitations/bulk?dry_run=${dry_run ? 1 : 0}`, init);
};

function* createInvitationsSaga(action: PayloadAction<InvitationDTO>) {
  const { mode } = action.payload;
  let result;
  switch (mode) {
    case 'single':
    case 'many':
      result = (yield createInvitations(action.payload)) as Await<ReturnType<typeof createInvitations>>;
      break;
    case 'ids':
      result = (yield updateInvitations(action.payload)) as Await<ReturnType<typeof updateInvitations>>;
      break;
    default:
      return;
  }

  switch (result.type) {
    case 'ok':
      const { dry_run } = action.payload;
      const { rows } = result.value.data;
      if (dry_run) {
        yield put(errorSlice.actions['CLEAR:VALIDATION_ERRORS']());
        yield put(emailSlice.actions['GET:PREVIEW:OK']({ preview: rows, email: action.payload }));
      } else {
        yield put(emailSlice.actions['REMOVE:PREVIEW']());
        yield put(emailSlice.actions['SEND:OK'](result.value));
      }
      break;
    case 'validation-error':
      yield put(errorSlice.actions['SET:VALIDATION_ERRORS'](Object.values(result.value.validation)));
      break;
    default:
      break;
  }
  return;
}

const updateInvitations = async (data: InvitationDTO): Promise<Result<sendEmailResponse, APIValidationError>> => {
  const { dry_run, ...rest } = data;
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };

  return apiCall<sendInvitationEmailResponse>(`${baseURL}/api/invitations/bulk?dry_run=${dry_run ? 1 : 0}`, init);
};

const deleteInvitations = async (ids: number[]): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
    body: JSON.stringify({ ids }),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/invitations`, init);
};

function* deleteInvitationsSaga(action: PayloadAction<number[]>) {
  yield put(infoSlice.actions['LOADING:ON']());
  const result = (yield deleteInvitations(action.payload)) as Await<ReturnType<typeof deleteInvitations>>;

  switch (result.type) {
    case 'ok':
      yield put(infoSlice.actions['DELETE_INVITATIONS:OK'](action.payload));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Invitation deleted.'), severity: 'success' }),
      );
      break;
    case 'validation-error':
      yield put(infoSlice.actions['LOADING:OFF']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const respondInvitation = async (data: RespondInvitationDTO): Promise<Result<{ data: any }, APIValidationError>> => {
  const { id, ...rest } = data;
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/invitations/${id}/respond`, init);
};

function* respondInvitationSaga(action: PayloadAction<RespondInvitationDTO>) {
  yield put(infoSlice.actions['LOADING:ON']());
  const result = (yield respondInvitation(action.payload)) as Await<ReturnType<typeof respondInvitation>>;

  switch (result.type) {
    case 'ok':
      yield put(infoSlice.actions['RESPOND_INVITATION:OK'](action.payload));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('Invitation responded.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(infoSlice.actions['LOADING:OFF']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(recruitmentSlice.actions.CREATE_INVITATIONS, createInvitationsSaga),
  takeLatest(recruitmentSlice.actions.DELETE_INVITATIONS, deleteInvitationsSaga),
  takeLatest(recruitmentSlice.actions.RESPOND_INVITATION, respondInvitationSaga),
];
