import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import errorSlice from '../error/slice';
import { setToken } from '../local-storage';
import userSlice from './slice';
import {
  deleteUserAvatarResponse,
  getUserByEmailResponse,
  getUserKeywordsResponse,
  getUserResponse,
  PostChangePasswordDTO,
  postChangePasswordResponse,
  postUserKeywordsDTO,
  UpdateMainAliasDTO,
  updateMainAliasResponse,
  UpdateUserAvatarDTO,
  updateUserAvatarResponse,
  UpdateUserDTO,
  updateUserResponse,
  verifyEmailRequest,
} from './types';
import { baseURL } from '../root-saga';
import authSlice from '../auth/slice';
import i18next from '../../i18n';
import history from '../history';
import { getRouteByName } from '../../router/routes';

const getUser = async (): Promise<Result<getUserResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<getUserResponse>(`${baseURL}/api/user`, init);
};

function* getUserSaga() {
  const result = (yield getUser()) as Await<ReturnType<typeof getUser>>;

  switch (result.type) {
    case 'ok':
      const { impersonated, is_superadmin } = result.value.data;
      yield put(authSlice.actions.SET_EXTRA_FIELDS({ impersonated, is_superadmin }));
      yield put(userSlice.actions['GET:OK'](result.value.data));
      break;
    case 'error':
      yield put(userSlice.actions['GET:KO']());
      break;
    default:
      yield put(userSlice.actions['GET:KO']());
      break;
  }
  return;
}

const updateUser = async (data: UpdateUserDTO): Promise<Result<updateUserResponse, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<updateUserResponse>(`${baseURL}/api/user`, init);
};

function* updateUserSaga(action: PayloadAction<UpdateUserDTO>) {
  const result = (yield updateUser(action.payload)) as Await<ReturnType<typeof updateUser>>;
  switch (result.type) {
    case 'ok':
      const { person } = result.value.data;
      yield put(userSlice.actions['UPDATE:OK'](person));
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Profile saved.'), severity: 'success' }));
      yield put(errorSlice.actions['CLEAR:VALIDATION_ERRORS']());
      history.goBack();
      break;
    case 'validation-error':
      yield put(errorSlice.actions['SET:VALIDATION_ERRORS'](Object.values(result.value.validation)));
      break;
    default:
      break;
  }
  return;
}

const updateUserAvatar = async (
  data: UpdateUserAvatarDTO,
): Promise<Result<updateUserAvatarResponse, APIValidationError>> => {
  const { avatar } = data;

  const formData = new FormData();
  formData.append('avatar', avatar);

  const init = {
    method: 'POST',
    auth: true,
    defaultContentType: true,
    body: formData,
  };

  return apiCall<updateUserAvatarResponse>(`${baseURL}/api/user/avatar`, init);
};

function* updateUserAvatarSaga(action: PayloadAction<UpdateUserAvatarDTO>) {
  const result = (yield updateUserAvatar(action.payload)) as Await<ReturnType<typeof updateUserAvatar>>;

  switch (result.type) {
    case 'ok':
      yield put(userSlice.actions['AVATAR:UPDATE:OK'](result.value.data));
      break;
    case 'error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Avatar upload failed.'), severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const deleteUserAvatar = async (): Promise<Result<deleteUserAvatarResponse, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall<deleteUserAvatarResponse>(`${baseURL}/api/user/avatar`, init);
};

function* deleteUserAvatarSaga() {
  const result = (yield deleteUserAvatar()) as Await<ReturnType<typeof deleteUserAvatar>>;

  switch (result.type) {
    case 'ok':
      yield put(userSlice.actions['AVATAR:DELETE:OK']());
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const postChangePassword = async (
  data: PostChangePasswordDTO,
): Promise<Result<postChangePasswordResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<postChangePasswordResponse>(`${baseURL}/api/user/change-password`, init);
};

function* changeUserPasswordSaga(action: PayloadAction<PostChangePasswordDTO>) {
  const result = (yield postChangePassword(action.payload)) as Await<ReturnType<typeof postChangePassword>>;

  switch (result.type) {
    case 'ok':
      yield put(userSlice.actions['CHANGE_PASSWORD:OK']());
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Password changed.'), severity: 'success' }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const postUpdateMainAlias = async (
  data: UpdateMainAliasDTO,
): Promise<Result<updateMainAliasResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<updateMainAliasResponse>(`${baseURL}/api/user/update-main-alias`, init);
};

function* updateMainAliasSaga(action: PayloadAction<UpdateMainAliasDTO>): Generator<any, void, any> {
  const result = (yield postUpdateMainAlias(action.payload)) as Await<ReturnType<typeof postUpdateMainAlias>>;

  switch (result.type) {
    case 'ok':
      // Manage action
      // 1. set new token given in the response
      // 2. change user id to selected alias id
      yield setToken(result.value.data.token);
      yield put(userSlice.actions['UPDATE_MAIN_ALIAS:OK']({ user_id: action.payload.user_id }));
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Email changed.'), severity: 'success' }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const getUserKeywords = async (): Promise<Result<getUserKeywordsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<getUserKeywordsResponse>(`${baseURL}/api/user/keywords`, init);
};

function* getUserKeywordsSaga() {
  const result = (yield getUserKeywords()) as Await<ReturnType<typeof getUserKeywords>>;

  switch (result.type) {
    case 'ok':
      yield put(userSlice.actions['GET:KEYWORDS:OK'](result.value));
      break;
    case 'validation-error':
      break;
    default:
      break;
  }
  return;
}

const postUserKeywords = async (data: postUserKeywordsDTO): Promise<Result<any, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<any>(`${baseURL}/api/user/keywords`, init);
};

function* postUserKeywordsSaga(action: PayloadAction<postUserKeywordsDTO>) {
  const result = (yield postUserKeywords(action.payload)) as Await<ReturnType<typeof postUserKeywords>>;

  switch (result.type) {
    case 'ok':
      yield put(userSlice.actions['POST:KEYWORDS:OK'](action.payload));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('Keywords saved with success.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      break;
    default:
      break;
  }
  return;
}

export const getUserByEmail = async (email: string): Promise<Result<getUserByEmailResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<getUserByEmailResponse>(`${baseURL}/api/users?email=${email}`, init);
};

export const postAddUserAlias = async (data: verifyEmailRequest): Promise<Result<any, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<any>(`${baseURL}/api/user/alias`, init);
};

function* postAddUserAliasSaga(action: PayloadAction<verifyEmailRequest>) {
  const result = (yield postAddUserAlias(action.payload)) as Await<ReturnType<typeof postAddUserAlias>>;

  switch (result.type) {
    case 'ok':
      const transformedResult = {
        [result.value.data.alias.id]: {
          ...result.value.data.alias,
        },
      };
      yield put(userSlice.actions['POST:ALIAS:OK'](transformedResult));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('Your email address has been added.'),
          severity: 'success',
        }),
      );

      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }

  return;
}

export const deleteUserAlias = async (userId: number): Promise<Result<number, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall<number>(`${baseURL}/api/user/alias/${userId}`, init);
};

function* deleteUserAliasSaga(action: PayloadAction<number>) {
  const result = (yield deleteUserAlias(action.payload)) as Await<ReturnType<typeof deleteUserAlias>>;
  switch (result.type) {
    case 'ok':
      yield put(userSlice.actions['DELETE:ALIAS:OK'](action.payload));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Email address deleted.'), severity: 'success' }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(userSlice.actions['GET'].type, getUserSaga),
  takeLatest(userSlice.actions['UPDATE'].type, updateUserSaga),
  takeLatest(userSlice.actions['AVATAR:UPDATE'].type, updateUserAvatarSaga),
  takeLatest(userSlice.actions['AVATAR:DELETE'].type, deleteUserAvatarSaga),
  takeLatest(userSlice.actions['CHANGE_PASSWORD'].type, changeUserPasswordSaga),
  takeLatest(userSlice.actions['UPDATE_MAIN_ALIAS'].type, updateMainAliasSaga),
  takeLatest(userSlice.actions['GET:KEYWORDS'], getUserKeywordsSaga),
  takeLatest(userSlice.actions['POST:KEYWORDS'], postUserKeywordsSaga),
  takeLatest(userSlice.actions['POST:ALIAS'], postAddUserAliasSaga),
  takeLatest(userSlice.actions['DELETE:ALIAS'], deleteUserAliasSaga),
];
