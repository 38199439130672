import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#D3E1FF' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="121" height="121" fill="none" viewBox="0 0 121 121">
      <g fill={color} clipPath="url(#clip0_2011_32)">
        <path
          fillRule="evenodd"
          d="M42.972 10.118c-13.807 0-25 11.193-25 25s11.193 25 25 25 25-11.193 25-25-11.193-25-25-25m-15 25c0-8.284 6.716-15 15-15s15 6.716 15 15-6.716 15-15 15-15-6.716-15-15"
          clipRule="evenodd"
        ></path>
        <path d="M25.472 70.118a25 25 0 0 0-25 25v10a5 5 0 0 0 10 0v-10a15 15 0 0 1 15-15h35a15 15 0 0 1 15 15v10a5 5 0 0 0 10 0v-10a25 25 0 0 0-25-25zM100.472 35.118a5 5 0 0 1 5 5v10h10a5 5 0 1 1 0 10h-10v10a5 5 0 1 1-10 0v-10h-10a5 5 0 1 1 0-10h10v-10a5 5 0 0 1 5-5"></path>
      </g>
      <defs>
        <clipPath id="clip0_2011_32">
          <path fill="#fff" d="M.472.118h120v120h-120z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
