import React, { useState } from 'react';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { selectErrorState, selectKeywordState } from '../../store/selectors';
import { Button, InputText, SelectRadioButton, Select, CancelButton } from '../../components/ui';
import keywordSlice from '../../store/keyword/slice';
import FileUploadUpdater from '../../components/FileUploadUpdater/FileUploadUpdater';
import { postKeywordsBulk } from '../../store/keyword/sagas';
import KeywordsTree from '../../components/KeywordsTree/KeywordsTree';
import styled, { ThemeProvider } from 'styled-components';
import { DataNode, KeywordsComplexTreeAdapter } from '../../helpers/keywords';
import { OpenSnackBarDTO } from '../../store/error/types';
import errorSlice from '../../store/error/slice';
import { TreeItemIndex } from 'react-complex-tree';
import { downloadFile, getHref } from '../../helpers/download';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import { AddButton } from '../../components/ui/inputs/AddButton/AddButton';
import useTheme from '@material-ui/core/styles/useTheme';
import Download2 from '../../icons/Download2';
import Maximize from '../../icons/Expand';
import Collapse from '../../icons/Collapse';
import { useTranslation } from 'react-i18next';
import UserPlus from '../../icons/UserPlus';
import Key from '../../icons/Key';

type KEYWORD_INPUT_TYPE = 'single' | 'many';
type KEYWORD_RELATIONSHIP = 'parent' | 'child';

interface Props {
  keywordById: { [key: number]: Keyword };
  keywordTree: { [key: number]: number[] };
  createKeywordAction: (data: KeywordDTO) => void;
  getKeywordsAction: () => void;
  updateKeywordTreeAction: (data: KeywordTreeDTO) => void;
  renameKeywordAction: (data: KeywordDTO) => void;
  deleteKeywordAction: (data: number) => void;
  onSaveSuccess?: () => void;
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
}

const SettingsKeywordsPage: React.FC<Props> = ({
  keywordById,
  keywordTree,
  createKeywordAction,
  getKeywordsAction,
  updateKeywordTreeAction,
  renameKeywordAction,
  deleteKeywordAction,
  onSaveSuccess,
  openSnackBarAction,
}) => {
  const { t, i18n } = useTranslation();
  const initKeyword = { id: 0, name: '', parent_id: null };
  const [keywordData, setKeywordData] = useState<KeywordDTO>(initKeyword);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [mode, setMode] = useState<KEYWORD_INPUT_TYPE | undefined>('single');
  const [file, setFile] = useState<File | undefined>(undefined);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [expandedItems, setExpandedItems] = useState<TreeItemIndex[]>([]);
  const [relationship, setRelationship] = useState<KEYWORD_RELATIONSHIP | undefined>('parent');

  const theme = useTheme();

  const dataTree = new KeywordsComplexTreeAdapter().transform(keywordById, keywordTree);

  const hasKeywords = Object.keys(keywordTree).length > 0;
  const hasChildren = Object.values(keywordTree).filter((children) => children.length > 0).length > 0;

  const toggleDialog = () => {
    clearInput();
    setVisible(!visible);
  };

  const clearInput = () => {
    setMode('single');
    setFile(undefined);
    setShowSave(false);
    setKeywordData(initKeyword);
    setRelationship('parent');
  };

  const getParentIds = (): number[] => {
    return Object.keys(keywordTree).map((keywordId) => parseInt(keywordId));
  };

  let content = undefined;
  if (mode === 'single') {
    content = (
      <fieldset className="fieldsetWrapper fieldsetSingle">
        <legend className="fieldsetLegend">{t('New keyword')}</legend>

        <StyledInputTextWrapper className="flex flex-row mb-3">
          <InputText
            label={t('Name')}
            value={keywordData.name}
            onChange={(e) => setKeywordData({ ...keywordData, name: e.target.value })}
            className="w-full"
          />
        </StyledInputTextWrapper>

        {hasKeywords && relationship === 'child' && (
          <StyledSelectParentKeywords className="mt-4 flex items-center">
            <Select
              label={t('Area')}
              options={getParentIds().map((keywordId) => ({
                id: keywordId.toString(),
                label: keywordById[keywordId].name,
              }))}
              defaultItem={{ label: t('Choose area') }}
              onChange={(value) => {
                setKeywordData({ ...keywordData, parent_id: parseInt(value) });
              }}
              value={keywordData.parent_id?.toString()}
            />
          </StyledSelectParentKeywords>
        )}
      </fieldset>
    );
  } else if (mode === 'many') {
    content = (
      <fieldset className="fieldsetWrapper fieldsetMany">
        <legend className="fieldsetLegend">{t('Keywords bulk upload')}</legend>

        <div className="my-2">
          <FileUploadUpdater
            templateName="keywords"
            doPreview={(file) => {
              setFile(file);
              return postKeywordsBulk({ file, dry_run: true });
            }}
            onFeedbackHandler={(data) => {
              const hasNoError = data.data.rows.filter((value) => !value.has_error).length > 0;
              setShowSave(hasNoError);
            }}
          />
        </div>
      </fieldset>
    );
  }

  // If mode is single and name is empty or keyword is child and there's no selected parent, save button is disabled
  const saveIsDisabled =
    (mode == 'single' && !keywordData.name) ||
    (relationship == 'child' && (!keywordData.name || !keywordData.parent_id));

  return (
    <ThemeProvider theme={theme}>
      {hasKeywords ? (
        <div className="text-sm max-w-4xl">
          <div className="flex items-center mb-8">
            <StyledButtonWrapper className="flex" style={{ flexGrow: 1 }}>
              <AddButton label={t('Add Keywords')} onClick={toggleDialog} />

              <div className="flex justify-end " style={{ flexGrow: 1 }}>
                <Button
                  className="expand-button ml-8"
                  onClick={() => {
                    const parentIdsTransform = getParentIds().map((dataId) => dataId.toString());
                    setExpandedItems(expandedItems.length > 0 ? [] : parentIdsTransform);
                  }}
                  disabled={!hasKeywords || !hasChildren}
                  variant="text"
                  icon={expandedItems.length > 0 ? <Collapse /> : <Maximize />}
                >
                  <span className="ml-4 block w-20 text-left">
                    {expandedItems.length > 0 ? t('Collapse all') : t('Expand all')}
                  </span>
                </Button>

                <Button
                  className="export-button ml-8"
                  disabled={!hasKeywords}
                  onClick={() => downloadFile(getHref('/web/keywords/export'))}
                  variant="text"
                  icon={<Download2 />}
                >
                  <span className="ml-4">{t('Export Keywords')}</span>
                </Button>
              </div>
            </StyledButtonWrapper>
          </div>

          {hasKeywords && <p className="mb-4">{t('You can drag and drop a topic keyword into an area keyword.')}</p>}

          {Object.keys(keywordTree).length > 0 && (
            <KeywordsTree
              dataTree={dataTree}
              onDrop={(items) => {
                const reverseDataTree = new KeywordsComplexTreeAdapter().reverse(
                  items as { [index: string]: DataNode },
                );
                updateKeywordTreeAction({ tree: reverseDataTree });
              }}
              onRenameItem={(id: number, name: string) => {
                renameKeywordAction({ id, name, parent_id: keywordById[id].parent_id });
              }}
              onDelete={deleteKeywordAction}
              expandItemsValue={expandedItems}
              handleExpandedItemsChange={(newValue) => setExpandedItems(newValue)}
            />
          )}
        </div>
      ) : (
        <div className="h-full flex justify-center items-center">
          <StyledMessageWrapper className="flex justify-center items-center flex-col mb-6">
            <StyledIcon>
              <Key />
            </StyledIcon>
            <h2 className="text-xl font-bold w-max mt-3">{t('Organise your content with smart keywords')}</h2>
            <p className="mt-3 mb-6 text-center">
              {t('Add keywords to improve categorisation and make your content easier to find and manage.')}
            </p>

            <div className="flex items-center">
              <StyledAddNewKeywordWrapper className="flex items-center">
                <AddButton label={t('Add keywords')} onClick={toggleDialog} />
              </StyledAddNewKeywordWrapper>
            </div>
          </StyledMessageWrapper>
        </div>
      )}

      {visible && (
        <SimpleDialog handleClose={toggleDialog} hideCancel={true}>
          <StyledTitle>{t('Add Keywords')}</StyledTitle>

          <StyledFieldsetWrapper>
            <fieldset className={`text-sm`}>
              <StyledSelectRadioButton
                label={t('How many keywords do you want to create?')}
                options={[
                  { id: 'single', label: t('Just one') },
                  { id: 'many', label: t('Many') },
                ]}
                value={mode}
                onChange={(value) => {
                  setMode(value as KEYWORD_INPUT_TYPE);
                }}
              />
              <hr className="my-4 border-dashed border-gray-300" />

              {/* Select if we insert a parent keyword or child keyword */}
              {mode == 'single' && (
                <div>
                  <StyledSelectRadioButton
                    label={t('Keyword type') + ':'}
                    options={[
                      { id: 'parent', label: t('Area') },
                      { id: 'child', label: t('Topic') },
                    ]}
                    value={relationship}
                    onChange={(value) => {
                      setRelationship(value as KEYWORD_RELATIONSHIP);
                      setKeywordData({ ...keywordData, parent_id: null });
                    }}
                  />
                </div>
              )}
            </fieldset>
          </StyledFieldsetWrapper>

          <StyledContent>{content}</StyledContent>

          <StyledButtons>
            <CancelButton onClick={toggleDialog}>{t('Cancel')}</CancelButton>

            {mode == 'single' && (
              <Button
                onClick={() => {
                  createKeywordAction(keywordData);
                  clearInput();
                  onSaveSuccess?.();
                  toggleDialog();
                }}
                disabled={saveIsDisabled}
              >
                {t('Save')}
              </Button>
            )}
            {mode == 'many' && showSave && (
              <Button
                onClick={async () => {
                  if (file) {
                    const result = await postKeywordsBulk({ file, dry_run: false });
                    switch (result.type) {
                      case 'ok':
                        getKeywordsAction(); // Refresh keywords
                        clearInput();
                        openSnackBarAction({ message: 'Data saved with success.', severity: 'success' });
                        onSaveSuccess?.();
                        toggleDialog();
                        break;
                      case 'validation-error':
                        openSnackBarAction({ message: result.value.validation[0].message, severity: 'error' });
                        break;
                    }
                  }
                }}
              >
                {t('Save')}
              </Button>
            )}
          </StyledButtons>
        </SimpleDialog>
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  keywordById: selectKeywordState(state).keywordById,
  keywordTree: selectKeywordState(state).keywordTree,
  validationErrors: selectErrorState(state).validationErrors,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createKeywordAction: (data: KeywordDTO) => dispatch(keywordSlice.actions.CREATE_KEYWORD(data)),
  getKeywordsAction: () => dispatch(keywordSlice.actions['GET_KEYWORDS']()),
  updateKeywordTreeAction: (data: KeywordTreeDTO) => dispatch(keywordSlice.actions.UPDATE_TREE(data)),
  renameKeywordAction: (data: KeywordDTO) => dispatch(keywordSlice.actions.RENAME_KEYWORD(data)),
  deleteKeywordAction: (data: number) => dispatch(keywordSlice.actions.DELETE_KEYWORD(data)),
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsKeywordsPage);

const StyledInputTextWrapper = styled.div`
  label {
    align-self: center;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 0;
    min-width: 5.2rem;
  }

  > div {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-self: center;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;

  .fieldsetWrapper {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .fieldsetLegend {
    padding: 0 13px;
    font-size: 0.875rem;
    color: #999;
  }

  .fieldsetSingle {
    width: 31rem;
  }

  .fieldsetMany {
    min-width: 31rem;
  }

  .k-widget {
    max-height: 23rem;
  }
`;

const StyledTitle = styled.h2`
  border-bottom: 2px dotted #e3e5e6;
  padding: 0;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`;

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  padding-top: 1rem;
  padding-right: 0;
  padding-bottom: 1.5rem;
`;

const StyledFieldsetWrapper = styled.div`
  fieldset {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 2rem auto 1rem auto;
    padding: 1rem;
  }
  .disabled {
    > div {
      label {
        color: #ccc;
        pointer-events: none;
      }
      > div {
        input {
          border-color: #ccc;
          pointer-events: none;
        }
      }
    }
  }
`;

const StyledSelectRadioButton = styled(SelectRadioButton)`
  display: flex;
  align-items: center;

  label {
    margin-right: 1rem;
    vertical-align: text-bottom;
  }
`;

const StyledSelectParentKeywords = styled.div`
  .labelSelect {
    align-self: center;
    margin-right: 10px;
    margin-bottom: 0;
    min-width: 5.2rem;
  }

  .k-widget {
    max-height: 23rem;
    flex-grow: 1;
  }

  .dropdown-wrapper,
  .k-widget {
    width: 100%;
  }
`;

const StyledButtonWrapper = styled.div`
  .expand-button,
  .export-button {
    color: ${(props) => props.theme.palette.primary.main};
    padding-left: 0;
    padding-right: 0;
  }
  .addButton {
    margin-left: 0;
  }
`;

const StyledMessageWrapper = styled.div`
  max-width: 32rem;

  h2 {
    font-size: 1.45rem;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
`;

const StyledIcon = styled.div`
  svg {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const StyledAddNewKeywordWrapper = styled.div`
  .addButton {
    margin-left: 0;

    svg {
      width: 3rem;
    }
    span {
      font-size: 1.1rem;
      font-weight: 700;
      margin-left: 0.3rem;
    }
  }
`;
