import React, { useState } from 'react';
import { selectCurrentUser, selectConference } from '../../store/selectors';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import ConferenceRequestForm, {
  ConferenceRequestFormFields,
} from '../../components/ConferenceRequestForm/ConferenceRequestForm';
import { validationErrorTransformer } from '../../store/api';
import conferenceSlice from '../../store/conference/slice';
import { ValidatedField } from '../../store/api.d';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { Conference, Track } from '../../store/conference/types';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { AddButton } from '../../components/ui/inputs/AddButton/AddButton';
import TrackCard from '../../components/TrackCard/TrackCard';
import TrackDialog from '../../components/dialogs/TrackDialog/TrackDialog';
import Loading from '../../components/Loading/Loading';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import history from '../../store/history';
import { getRouteByName } from '../../router/routes';
import { fillRoutePath } from '../../helpers/path';

interface ConferenceCreatePageProps {
  loading: boolean;
  timezone: string | null;
  dateFormat: string | null;
  tracksById: { [key: string]: Track };
  validationError?: ValidatedField[];
  updateConferenceAction: (data: ConferenceRequestFormFields) => void;
  clearValidationErrors: () => void;
  addTrackAction: (data: Track) => void;
  deleteTrackAction: (formId: number) => void;
  conferencesById: { [key: string]: Conference };
  editTrackAction: (data: Track) => void;
}

const SettingsConferencePage: React.FC<ConferenceCreatePageProps> = ({
  loading,
  timezone,
  dateFormat,
  tracksById,
  validationError,
  updateConferenceAction,
  clearValidationErrors,
  addTrackAction,
  deleteTrackAction,
  conferencesById,
  editTrackAction,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  const { slug }: { slug: string } = useParams();

  const currentConference = Object.values(conferencesById).find((conference) => conference.slug == slug);
  if (!currentConference) {
    return <Loading />;
  }

  const tracks = Object.values(tracksById).filter((track) => track.conference_id === currentConference.id);
  const canDeleteTrack = tracks.length > 1; // At last there must be one track defined

  let valError = undefined;
  if (validationError) {
    valError = validationErrorTransformer(validationError) as unknown as ConferenceRequestFormFields;
  }

  const save_handler = (form: ConferenceRequestFormFields) => {
    if (currentConference) {
      updateConferenceAction({
        ...form,
        id: currentConference.id,
      });
    }
  };

  const emptyTrack: Track = {
    id: 0,
    conference_id: currentConference.id,
    name: '',
    slug: '',
    chairs: [],
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="flex items-center w-full">
        <button
          onClick={() => history.push(getRouteByName('RouteConferences').path)}
          className="bg-blue-700 text-white px-1.5 py-0.5 h-6 leading-none"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <StyledTitle className="ml-8">{t('Conference settings')}</StyledTitle>
      </div>
      <div className="w-full">
        <ConferenceRequestForm
          loading={loading}
          timezone={timezone}
          dateFormat={dateFormat}
          initConference={currentConference}
          validation_error={valError}
          save_handler={save_handler}
          clearValidationErrors={() => clearValidationErrors()}
        />
      </div>
      <div>
        <div className="flex mb-8 items-center">
          <h2 className="font-bold text-xl">{t('Conference tracks')}</h2>
          <AddButton
            label={t('Add track')}
            onClick={() =>
              setDialog(
                <TrackDialog track={emptyTrack} handleOk={addTrackAction} handleClose={() => setDialog(undefined)} />,
              )
            }
          />
        </div>
        <StyledCardWrapper>
          {tracks.map((track) => (
            <TrackCard
              key={track.id}
              track={track}
              conference={currentConference}
              handleDeleteTrack={
                canDeleteTrack
                  ? () =>
                      setDialog(
                        <SimpleDialog
                          open={true}
                          handleClose={() => setDialog(undefined)}
                          handleOk={() => {
                            deleteTrackAction(track.id);
                          }}
                          title={t('Remove track?')}
                        >
                          <p className="text-sm mb-2">
                            {t(
                              `Are you sure you want to remove {{trackName}}? Take into account that this action can't be undone.`,
                              { trackName: track.name },
                            )}
                          </p>
                        </SimpleDialog>,
                      )
                  : undefined
              }
              handleEditTrack={() =>
                setDialog(
                  <TrackDialog track={track} handleOk={editTrackAction} handleClose={() => setDialog(undefined)} />,
                )
              }
            />
          ))}
        </StyledCardWrapper>
      </div>
      {dialog}
    </ThemeProvider>
  );
};

const StyledTitle = styled.h2`
  margin-right: 0.75rem;
  font-size: 1.25rem;
  font-weight: 700;
  width: 100%;
`;

const mapStateToProps = (state: AppState) => ({
  loading: selectConference(state).requestLoading,
  timezone: selectCurrentUser(state).person.timezone,
  dateFormat: selectCurrentUser(state).person.date_format,
  validationError: selectConference(state).validationError,
  conferencesById: selectConference(state).conferencesById,
  tracksById: selectConference(state).tracksById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateConferenceAction: (data: ConferenceRequestFormFields) =>
    dispatch(conferenceSlice.actions.UPDATE_CONFERENCE(data)),
  clearValidationErrors: () => dispatch(conferenceSlice.actions['CLEAR_VALIDATION_ERRORS']()),
  addTrackAction: (data: Track) => dispatch(conferenceSlice.actions.ADD_TRACK(data)),
  deleteTrackAction: (trackId: number) => dispatch(conferenceSlice.actions.DELETE_TRACK(trackId)),
  editTrackAction: (data: Track) => dispatch(conferenceSlice.actions.EDIT_TRACK(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsConferencePage);

const StyledCardWrapper = styled.div`
  ${tw`grid gap-5 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5`}
  max-width: 1440px;
  margin-bottom: 3rem;
`;
