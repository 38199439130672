import React, { useEffect } from 'react';
import Loading from '../../components/Loading/Loading';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import { Role } from '../../store/conference/types';
import { AppState } from '../../store/state';
import { selectInfo, selectKeywordState, selectTable, selectTrackRoles } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import infoSlice from '../../store/info/slice';
import { connect } from 'react-redux';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { formatKeywordColumn } from '../../helpers/table';
import { useTranslation } from 'react-i18next';
import { Button, Select } from '../../components/ui';
import { RoleTree } from '../../helpers/role';
import { parsePositiveNumber } from '../../helpers/validation';
import styled from 'styled-components';
import ReviewersToReviewers from '../../icons/ReviewersToReviewers';
import { CustomColumnsDataGet } from '../../store/table/types';
import tableSlice from '../../store/table/slice';

interface Props {
  loading: boolean;
  info: Info;
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  keywordById: { [key: number]: Keyword };
  setSelectorValuesAction: (data: { fromRoleId: number; toRoleId: number }) => void;
  customColumnsData: { [key: string]: { [key: number]: { [key: string]: any } } };
  getCustomColumnsDataAction: (data: CustomColumnsDataGet) => void;
}

const ReviewersToReviewersPage: React.FC<Props> = ({
  loading,
  info,
  roleById,
  getInfoAction,
  keywordById,
  setSelectorValuesAction,
  customColumnsData,
  getCustomColumnsDataAction,
}) => {
  const chairInfo = info as ChairInfo;
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'reviewers_to_reviewers';

  const { fromRoleId, toRoleId } = chairInfo[friendlyName].selectorValues;
  const reviewerRoles = Object.values(roleById).filter((role) => role.type == 'reviewer');
  const tree = new RoleTree(reviewerRoles);

  const rolesValid = fromRoleId > 0 && toRoleId > 0;
  const queryParams = { from_role_id: fromRoleId, to_role_id: toRoleId };

  const fetchData = () => {
    getInfoAction({ friendlyName: 'users' });
    getInfoAction({ friendlyName, queryParams });
  };

  const fetchCustomColumnsData = () => {
    getCustomColumnsDataAction({ tableFriendlyName: friendlyName, queryParams });
  };

  const getOptions = (fromRoleId?: number) => {
    if (fromRoleId) {
      return tree.childrenOf(fromRoleId).concat(tree.parentOf(fromRoleId));
    } else {
      return reviewerRoles.filter((role) => tree.childrenOf(role.id).length > 0 || tree.parentOf(role.id).length > 0);
    }
  };

  useEffect(() => {
    if (rolesValid) {
      if (Object.keys(info['users'].byId).length === 0) {
        fetchData();
      } else if (!customColumnsData[friendlyName]) {
        fetchCustomColumnsData();
      }
    }
  }, [toRoleId]);

  /* Build input data */
  const inputData: unknown[] = [];
  if (rolesValid) {
    Object.values(chairInfo.users.byId).forEach((register) => {
      register.role_ids.forEach((roleId, index) => {
        if (roleId == fromRoleId) {
          const personRoleId = register.person_role_ids[index];

          const newRegister = {
            ...register,
            id: personRoleId,
            role_id: roleId,
            person_role_id: personRoleId,
            to_role_id: toRoleId,
          };

          // Add Role description
          // @ts-ignore
          newRegister.role = roleById[roleId].description;

          formatKeywordColumn(newRegister, keywordById);

          inputData.push(newRegister);
        }
      });
    });
  }

  return (
    <>
      <StyledRelationRoles className="flex">
        <Select
          value={fromRoleId.toString()}
          options={getOptions().map((role) => ({ id: role.id.toString(), label: role.description }))}
          defaultItem={{ label: t('Choose role') + '...' }}
          onChange={(value) => {
            const fromRoleId = parsePositiveNumber(value) ?? 0;
            setSelectorValuesAction({ fromRoleId: fromRoleId, toRoleId: 0 });
          }}
        />
        <span className="mx-4 mt-1">assigned to</span>

        <Select
          disabled={!fromRoleId}
          value={toRoleId.toString()}
          options={
            fromRoleId
              ? getOptions(fromRoleId).map((role) => ({ id: role.id.toString(), label: role.description }))
              : []
          }
          defaultItem={{ label: t('Choose role') + '...' }}
          onChange={(value) => {
            const toRoleId = parsePositiveNumber(value) ?? 0;
            setSelectorValuesAction({ fromRoleId: fromRoleId, toRoleId: toRoleId });
          }}
        />
      </StyledRelationRoles>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : rolesValid ? (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={fetchData}
            getCustomColumnsData={fetchCustomColumnsData}
          />
        ) : (
          <div className="h-full flex justify-center items-center">
            <StyledMessageWrapper className="flex justify-center items-center flex-col mb-40">
              <StyledIcon>
                <ReviewersToReviewers />
              </StyledIcon>
              <h2 className="text-xl font-bold w-max mt-3">{t('Select roles above to view data')}</h2>
              <p className="mt-3 mb-6 text-center">{t('Please select roles above to create a view for you.')}</p>
            </StyledMessageWrapper>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading,
  info: state.info.info,
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
  customColumnsData: selectTable(state).customColumnsData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  setSelectorValuesAction: (data: { fromRoleId: number; toRoleId: number }) =>
    dispatch(infoSlice.actions['ASSIGNMENTS:SET_SELECTOR_VALUES'](data)),
  getCustomColumnsDataAction: (data: CustomColumnsDataGet) =>
    dispatch(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET'](data)),
});

const StyledRelationRoles = styled.div`
  margin-bottom: 1rem;

  .labelSelect {
    display: none;
  }
  .dropdown-wrapper {
    margin-top: -0.3rem;
  }
`;

const StyledMessageWrapper = styled.div`
  max-width: 30rem;

  h2 {
    font-size: 1.45rem;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
`;

const StyledIcon = styled.div`
  svg {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const StyledButton = styled(Button)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 5.5rem;
  padding-right: 5.5rem;
  font-size: 0.975rem;
`;

export default connect(mapStateToProps, mapDispatchToProps)(ReviewersToReviewersPage);
