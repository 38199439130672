import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { selectCurrentRole } from '../../store/selectors';
import { Role } from '../../store/conference/types';
import { fillRoutePath } from '../../helpers/path';
import { getRouteByName } from '../../router/routes';
import PeopleChairPage from './PeopleChairPage';
import PeopleReviewerPage from './PeopleReviewerPage';
import { doesHandleReviewers } from '../../helpers/role';
import { Redirect } from 'react-router-dom';

interface Props {
  role: Role | null;
}

const PeoplePage: React.FC<Props> = ({ role }) => {
  if (role?.type == 'chair') {
    return <PeopleChairPage />;
  } else if (role?.type == 'reviewer' && doesHandleReviewers(role)) {
    return <PeopleReviewerPage />;
  } else {
    return <Redirect to={fillRoutePath(getRouteByName('RouteConferenceHome').path)} />;
  }
};

const mapStateToProps = (state: AppState) => ({
  role: selectCurrentRole(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PeoplePage);
