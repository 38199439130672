import React, { useEffect, useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import SendEmailForm from '../../SendEmailForm/SendEmailForm';
import {
  EmailFormState,
  EmailRecipientType,
  EmailSendDTO,
  EmailTemplate,
  PlaceholderByRecipientType,
  PreviewRow,
} from '../../../store/email/types';
import { selectEmailState, selectErrorState } from '../../../store/selectors';
import emailSlice from '../../../store/email/slice';
import errorSlice from '../../../store/error/slice';
import { useTranslation } from 'react-i18next';
import { ValidatedField } from '../../../store/api.d';

interface Props extends SimpleDialogProps {
  modelIds: number[];
  recipientType: EmailRecipientType;
  recipientIds?: number[];
  sendEmailAction: (data: EmailSendDTO) => void;
  clearValidationErrorsAction: () => void;
  removeEmailAction: () => void;
  removePreviewAction: () => void;
  formState: EmailFormState;
  emailRedux?: EmailSendDTO;
  preview?: PreviewRow[];
  totalToSend: number;
  placeholdersByRecipientType: PlaceholderByRecipientType;
  templatesById: { [key: number]: EmailTemplate };
  validationError?: ValidatedField[];
}

const SendEmailDialog: React.FC<Props> = ({
  modelIds,
  recipientType,
  recipientIds,
  sendEmailAction,
  clearValidationErrorsAction,
  removeEmailAction,
  removePreviewAction,
  formState,
  emailRedux,
  preview,
  totalToSend,
  placeholdersByRecipientType,
  templatesById,
  validationError,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState<EmailSendDTO>({
    ids: modelIds,
    subject: '',
    body: '',
    dry_run: true,
    recipient_type: recipientType,
    recipient_ids: recipientIds,
    template_id: undefined,
  });

  useEffect(() => {
    if (emailRedux?.token) {
      setEmail({ ...email, token: emailRedux.token });
    }
  }, [emailRedux?.token]);

  const onCloseHandler = () => {
    removeEmailAction();
    removePreviewAction();
    clearValidationErrorsAction();
    rest.handleClose();
  };

  const getStep = () => {
    switch (formState) {
      case 'COMPOSE':
        return {
          okButtonText: t('Preview'),
          handleOk: () => sendEmailAction(email),
        };
      case 'PREVIEW':
        return {
          previousButtonText: t('Continue editing'),
          handlePrevious: () => removePreviewAction(),
          okButtonText: t('Send'),
          handleOk: () => {
            if (email) {
              sendEmailAction({ ...email, dry_run: false });
            }
          },
        };
      case 'SENDING':
        return {
          okButtonText: t('Close'),
          handleOk: onCloseHandler,
        };
    }
  };

  const step = getStep();
  const isValid = !!email.subject && !!email.body;

  return (
    <SimpleDialog
      {...rest}
      previousButtonText={step?.previousButtonText}
      handlePrevious={step?.handlePrevious}
      okButtonText={step?.okButtonText}
      handleOk={step?.handleOk}
      disableOkButton={!isValid}
      closeOnOk={formState == 'SENDING'}
      hideCancel={formState == 'SENDING'}
      handleClose={formState != 'SENDING' ? onCloseHandler : () => {}} // In sending step, we don't want users to close modal in a way that is not clicking the Close button
    >
      <SendEmailForm
        placeholders={placeholdersByRecipientType[recipientType]}
        formState={formState}
        preview={preview}
        email={email}
        totalToSend={totalToSend}
        onChange={(value) => setEmail({ ...email, ...value })}
        templatesById={templatesById}
        validationError={validationError}
      />
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  formState: selectEmailState(state).formState,
  emailRedux: selectEmailState(state).email as EmailSendDTO | undefined,
  preview: selectEmailState(state).preview,
  totalToSend: selectEmailState(state).totalToSend,
  placeholdersByRecipientType: selectEmailState(state).placeholdersByRecipientType,
  templatesById: selectEmailState(state).templatesById,
  validationError: selectErrorState(state).validationErrors,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendEmailAction: (data: EmailSendDTO) => dispatch(emailSlice.actions['SEND'](data)),
  clearValidationErrorsAction: () => dispatch(errorSlice.actions['CLEAR:VALIDATION_ERRORS']()),
  removeEmailAction: () => dispatch(emailSlice.actions['REMOVE:EMAIL']()),
  removePreviewAction: () => dispatch(emailSlice.actions['REMOVE:PREVIEW']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailDialog);
