import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#D3E1FF' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="none" viewBox="0 0 120 120">
      <path
        fill={color}
        fillRule="evenodd"
        d="M5 29.908V90c0 8.261 6.739 15 15 15h80c8.261 0 15-6.739 15-15V29.919C114.956 21.695 108.234 15 100 15H20c-8.23 0-14.95 6.689-15 14.908m10.533-2.138C16.36 26.135 18.06 25 20 25h80c1.94 0 3.64 1.135 4.467 2.77L60 58.897zM105 39.603V90c0 2.739-2.261 5-5 5H20c-2.739 0-5-2.261-5-5V39.603l42.133 29.493a5 5 0 0 0 5.734 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
