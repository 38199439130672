import React, { useEffect } from 'react';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../../components/ui';
import InvitationStepper from '../../components/InvitationStepper/InvitationStepper';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { selectCurrentUser, selectInfo, selectTrackRoles } from '../../store/selectors';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import infoSlice from '../../store/info/slice';
import { Role } from '../../store/conference/types';
import { getUserOffset, transform } from '../../helpers/timezone';
import emailSlice from '../../store/email/slice';
import errorSlice from '../../store/error/slice';
import Loading from '../../components/Loading/Loading';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import { useTranslation } from 'react-i18next';
import { getInvitationStatusName } from '../../helpers/translations';
import UserPlus from '../../icons/UserPlus';

interface Props {
  loading: boolean;
  info: Info;
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  timezone: string | null;
  removeEmailAction: () => void;
  removePreviewAction: () => void;
  clearValidationErrorsAction: () => void;
}

const RecruitmentPage: React.FC<Props> = ({
  loading,
  info,
  roleById,
  getInfoAction,
  timezone,
  removeEmailAction,
  removePreviewAction,
  clearValidationErrorsAction,
}) => {
  const { t } = useTranslation();
  const friendlyName: TableFriendlyName = 'invitations';
  const [visible, setVisible] = React.useState<boolean>(false);

  const fetchData = () => getInfoAction({ friendlyName });

  useEffect(() => {
    if (Object.keys(info[friendlyName].byId).length === 0) {
      fetchData();
    }
  }, []);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  if (loading) return <Loading />;

  const chairInfo = info as ChairInfo;
  const { byId } = chairInfo[friendlyName];

  const utcOffset = getUserOffset(timezone);

  const inputData: Record<string, unknown>[] = [];
  Object.values(byId).forEach((register) => {
    const newRegister = { ...register };
    // @ts-ignore
    newRegister.role = roleById[newRegister.role_id].description;

    // @ts-ignore
    newRegister.expiration_date = transform(new Date(register.expiration_date), utcOffset); //new Date(dateOffset.toDateString());

    // @ts-ignore
    newRegister.status = getInvitationStatusName(newRegister.status, t);

    inputData.push(newRegister);
  });

  return (
    <>
      {inputData.length > 0 ? (
        <StyledContentWrapper>
          {/*
          OLD CODE FOR NEW INVITATION 
          <div className="flex items-center mb-8">
            <Button className="w-32 place-self-start" onClick={toggleDialog}>
              <span className="px-14">{t('New invitation')}</span>
            </Button>
          </div> 
          */}

          {/*visible && (
            <SimpleDialog handleClose={() => {}} hideCancel={true}>
              <StyledTitle>{t('New Invitation')}</StyledTitle>
              <InvitationStepper
                onClose={(formState) => {
                  toggleDialog();
                  removePreviewAction();
                  removeEmailAction();
                  clearValidationErrorsAction();
                  if (formState == 'SENDING') {
                    getInfoAction({ friendlyName }); // Refresh data
                  }
                }}
              />
            </SimpleDialog>
          )*/}

          <div className="h-full">
            <MyGridRedux friendlyName={friendlyName} inputData={inputData} getInfoAction={fetchData} />
          </div>
        </StyledContentWrapper>
      ) : (
        <>
          <div className="h-full flex justify-center items-center">
            <StyledMessageWrapper className="flex justify-center items-center flex-col mb-6">
              <StyledIcon>
                <UserPlus />
              </StyledIcon>
              <h2 className="text-xl font-bold w-max mt-3">{t('Start recruiting by sending invitations')}</h2>
              <p className="mt-3 mb-6 text-center">
                {t(
                  'Send recruitment emails to one or multiple people to join your committee members. Customize your message or use a template.',
                )}
              </p>

              <div className="flex items-center mb-8">
                <StyledButton className="w-32 place-self-start" onClick={toggleDialog}>
                  <span className="px-14">{t('New invitation')}</span>
                </StyledButton>
              </div>
            </StyledMessageWrapper>
          </div>
          {visible && (
            <SimpleDialog handleClose={() => {}} hideCancel={true}>
              <StyledTitle>{t('New Invitation')}</StyledTitle>
              <InvitationStepper
                onClose={(formState) => {
                  toggleDialog();
                  removePreviewAction();
                  removeEmailAction();
                  clearValidationErrorsAction();
                  if (formState == 'SENDING') {
                    fetchData(); // Refresh data
                  }
                }}
              />
            </SimpleDialog>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading,
  info: selectInfo(state).info,
  roleById: selectTrackRoles(state),
  timezone: selectCurrentUser(state).person.timezone,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  removeEmailAction: () => dispatch(emailSlice.actions['REMOVE:EMAIL']()),
  removePreviewAction: () => dispatch(emailSlice.actions['REMOVE:PREVIEW']()),
  clearValidationErrorsAction: () => dispatch(errorSlice.actions['CLEAR:VALIDATION_ERRORS']()),
});

const StyledContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h2`
  border-bottom: 2px dotted #e3e5e6;
  padding: 0;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`;

const StyledMessageWrapper = styled.div`
  max-width: 34rem;

  h2 {
    font-size: 1.45rem;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
`;

const StyledIcon = styled.div`
  svg {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const StyledButton = styled(Button)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 5.5rem;
  padding-right: 5.5rem;
  font-size: 0.975rem;
`;

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentPage);
