import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPermissionsResponse, PermissionState, UpdatePermissionRoleDTO } from './types';

export const initialState: PermissionState = {
  loading: false,
  byRoleId: {},
};

const permissionSlice = createSlice({
  name: 'PERMISSION',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET_PERMISSIONS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET_PERMISSIONS:OK']: (state, action: PayloadAction<getPermissionsResponse>) => ({
      ...state,
      loading: false,
      byRoleId: action.payload.data.byRoleId,
    }),
    ['UPDATE_PERMISSIONS']: (state, action: PayloadAction<UpdatePermissionRoleDTO>) => {
      const { value, target_role_id, permission } = action.payload;
      return {
        ...state,
        byRoleId: {
          ...state.byRoleId,
          [action.payload.target_role_id]: value
            ? [...state.byRoleId[target_role_id], permission]
            : state.byRoleId[target_role_id].filter((p) => p != permission),
        },
      };
    },
  },
});

export default permissionSlice;
