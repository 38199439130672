import React, { FC, useEffect } from 'react';
import { DateTimePickerProps } from '@progress/kendo-react-dateinputs/dist/npm/datetimepicker/DateTimePicker';
import { DateTimePicker, DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import { transform } from '../../helpers/timezone';
import styled from 'styled-components';
import { Popup } from '@progress/kendo-react-popup';

interface Props extends Omit<DateTimePickerProps, 'onChange'> {
  utcOffset: string; // As specified by https://momentjs.com/docs/#/manipulating/utc-offset/
  onChange?: (date: Date) => void;
  error?: boolean;
  helperText?: string;
  errors?: string;
  isReadOnly?: boolean;
  display?: 'horizontal' | 'vertical';
  customPopupClass?: string;
  appendToElement?: boolean;
}

const DateTimePickerWithOffset: FC<Props> = ({
  utcOffset,
  defaultValue,
  onChange,
  label,
  error = false,
  helperText,
  errors,
  required,
  isReadOnly,
  display,
  customPopupClass,
  appendToElement,
  ...props
}) => {
  const reverse = (date: Date) => {
    const dateStr = moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + utcOffset;
    return new Date(dateStr);
  };

  const [value, setValue] = React.useState<Date | null>(null);

  useEffect(() => {
    setValue(defaultValue ? transform(defaultValue, utcOffset) : null);
  }, [utcOffset, defaultValue]);

  const handleChange = (event: DateTimePickerChangeEvent) => {
    event.value && onChange?.(reverse(event.value));
  };

  const ref = React.useRef(null);

  return (
    <StyledPickerWrapper>
      <div
        className={`mb-3 ${display === 'vertical' ? 'flex flex-col' : 'flex'} justify-between date-wrapper ${
          isReadOnly ? 'date-readonly-wrapper' : ''
        }`}
        ref={ref}
      >
        {label && <label className="mr-2 mb-1.5 text-sm w-[6.5rem]">{required ? `${label} *` : label}</label>}
        <StyledPicker
          {...props}
          value={value}
          onChange={handleChange}
          className={`ml-0 w-[269px] ${error ? 'border-2 border-red-400' : 'border border-gray-400'}`}
          popup={(props) => {
            return (
              <Popup
                {...props}
                className={`${customPopupClass ?? ''} k-datetime-container`}
                appendTo={appendToElement ? ref.current : null}
                /* show={true} */
              />
            );
          }}
        />
      </div>
      {helperText && <StyledSpan className={`helperText text-sm pt-1.5`}>{helperText}</StyledSpan>}
      {errors && <span className="errorText">{errors}</span>}
    </StyledPickerWrapper>
  );
};

export default DateTimePickerWithOffset;

const StyledSpan = styled.span`
  color: #f44336;
  display: block;
`;

const StyledPickerWrapper = styled.div`
  .date-wrapper.date-readonly-wrapper {
    background-color: transparent;
    pointer-events: none;

    .k-datetimepicker {
      width: 265px;
      border-color: rgba(156, 163, 175, var(--tw-border-opacity));
      --tw-border-opacity: 0;

      .k-link.k-link-date {
        background-color: #fff;

        .k-icon.k-i-calendar {
          visibility: hidden;
        }
      }
    }
  }
`;

const StyledPicker = styled(DateTimePicker)`
  min-width: 265px;

  .k-picker-wrap {
    border: 0;
  }
  .k-input {
    font-size: 0.875rem;
  }
`;
