import React, { useState } from 'react';
import { Button, InputText, Select } from '../ui';
import { EmailTemplate, Placeholder } from '../../store/email/types';
import '../SendEmailForm/SendEmailForm.css';
import MarkdownEditor from '../ui/inputs/MarkdownEditor/MarkdownEditor';
import styled from 'styled-components';
import NewCopy from '../../icons/NewCopy';
import { useTranslation } from 'react-i18next';
import { validationErrorTransformer } from '../../store/api';
import { ValidatedField } from '../../store/api.d';

export interface EmailFormFields {
  subject: string;
  body: string;
  template_id?: number;
}

interface Props {
  onChange: (value: EmailFormFields) => void;
  placeholders: Placeholder[];
  value: EmailFormFields;
  templatesById: { [key: number]: EmailTemplate };
  hideTemplates?: boolean;
  subjectDisabled?: boolean;
  validationErrors?: ValidatedField[];
}

const EmailForm: React.FC<Props> = ({
  onChange,
  placeholders,
  value,
  templatesById,
  hideTemplates,
  subjectDisabled,
  validationErrors,
  children,
}) => {
  const { t, i18n } = useTranslation();
  const [FData, setFData] = useState<EmailFormFields>(value);
  const [showPlaceholders, setShowPlaceholders] = useState<boolean>(false);

  const handleChange = (data: EmailFormFields) => {
    setFData(data);
    onChange(data);
  };

  const errors = validationErrors ? validationErrorTransformer(validationErrors) : {};

  return (
    <StyledEmailFormWrapper>
      {!hideTemplates && (
        <Select
          label={t('Templates')}
          value={FData.template_id?.toString()}
          options={Object.values(templatesById).map((template) => {
            return {
              id: template.id.toString(),
              label: template.name,
            };
          })}
          onChange={(templateId) => {
            let data: EmailFormFields = { ...FData };
            if (templateId) {
              // Overwrite subject and body with the value specified in the template
              const template_id = parseInt(templateId);
              const template = templatesById[template_id];
              data = {
                ...data,
                subject: subjectDisabled ? value.subject : template.subject,
                body: template.body,
                template_id: template_id,
              };
              handleChange(data);
            }
          }}
          defaultItem={
            FData.template_id
              ? undefined
              : {
                  label:
                    Object.keys(templatesById).length > 0
                      ? t('No template selected')
                      : t('There are no templates. You can create them in the Email templates section.'),
                }
          }
        />
      )}

      <div className="flex flex-col">
        <InputText
          label={t('Subject')}
          required={!hideTemplates}
          value={FData.subject}
          onChange={(e) => handleChange({ ...FData, subject: e.target.value, body: value.body })}
          className="mb-4"
          disabled={subjectDisabled}
          error={!!errors.subject}
          helperText={errors.subject}
        />
        <MarkdownEditor
          label={t('Body')}
          required={!hideTemplates}
          defaultValue={FData.body}
          onChange={(markdown) => {
            // Here intentionally we do not modify the internal state
            const data = { ...FData, body: markdown };
            onChange(data);
          }}
          error={!!errors.body}
          helperText={errors.body}
        />
        <div className="flex justify-between w-full pt-3">
          <div>{children}</div>

          {placeholders.length > 0 && (
            <StyledShowButton
              type="button"
              variant="outlined"
              roundedEdges="rounded"
              onClick={() => setShowPlaceholders(!showPlaceholders)}
            >
              {showPlaceholders ? t('Hide placeholders') : t('Show placeholders')}
            </StyledShowButton>
          )}
        </div>
        {showPlaceholders && (
          <div className="mt-4 mb-4">
            <table className="table-auto placeholder-table w-full">
              <thead>
                <tr className="placeholder-tr">
                  <th className="placeholder-th w-1/2">{t('Value')}</th>
                  <th className="placeholder-th w-1/2">{t('Description')}</th>
                </tr>
              </thead>
              <tbody>
                {placeholders.map((placeholder, index) => (
                  <tr className="placeholder-tr" key={index}>
                    <td className="placeholder-td">
                      <div className="flex items-center justify-between">
                        {placeholder.value}

                        <StyledCopyButton
                          type="button"
                          variant="text"
                          icon={<NewCopy />}
                          iconPosition="left"
                          onClick={(e) => {
                            navigator.clipboard.writeText(placeholder.value);
                          }}
                        >
                          {t('Copy')}
                        </StyledCopyButton>
                      </div>
                    </td>
                    <td className="placeholder-td">{placeholder.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </StyledEmailFormWrapper>
  );
};

export default EmailForm;

const StyledCopyButton = styled(Button)`
  padding-left: 0.3rem;
  padding-right: 0.4rem;

  span {
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 500;
  }
  > span:first-child {
    margin-right: 0.3rem;
  }
`;
const StyledShowButton = styled(Button)`
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  padding-top: 0.9rem;
  padding-bottom: 0.7rem;
`;
const StyledEmailFormWrapper = styled.div`
  width: 40rem;

  .dropdown-wrapper {
    margin-bottom: 1rem;

    .k-dropdown {
      width: 100%;
    }
  }
`;
