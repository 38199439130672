import React from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import { getEmailCount } from '../../store/email/sagas';
import { useTranslation } from 'react-i18next';

interface Props {
  token: string;
  totalToSend: number;
}

const EmailSending: React.FC<Props> = ({ token, totalToSend }) => {
  const { t, i18n } = useTranslation();
  return (
    <ProgressBar
      title={t('Sending emails...')}
      fetchValue={async () => {
        const response = await getEmailCount(token);
        if (response.type === 'ok') {
          return Math.round((response.value.data.count / totalToSend) * 100);
        }
        return 0;
      }}
    />
  );
};

export default EmailSending;
