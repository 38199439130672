import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#D3E1FF' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="none" viewBox="0 0 120 120">
      <path
        fill={color}
        fillRule="evenodd"
        d="M108.535 6.464a5 5 0 0 1 0 7.072L102.071 20l11.464 11.465a5 5 0 0 1 0 7.07l-17.5 17.5a5 5 0 0 1-7.07 0L77.5 44.572 63.768 58.303a32.5 32.5 0 0 1 3.914 31.71 32.5 32.5 0 0 1-42.635 17.576 32.5 32.5 0 0 1-10.546-7.137l-.037-.038a32.502 32.502 0 0 1 23.094-55.077 32.5 32.5 0 0 1 19.061 5.973l44.845-44.846a5 5 0 0 1 7.071 0M53.609 61.78l-.138-.129a22.5 22.5 0 0 0-31.833 31.796A22.5 22.5 0 1 0 53.609 61.78M84.571 37.5l7.929 7.929L102.929 35l-7.93-7.929z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
