import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#D3E1FF' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="none" viewBox="0 0 120 120">
      <path
        fill={color}
        d="M25 20a5 5 0 0 0-5 5v45a5 5 0 0 0 5 5 5 5 0 0 1 0 10 15 15 0 0 1-15-15V25a15 15 0 0 1 15-15h45a15 15 0 0 1 15 15 5 5 0 0 1-10 0 5 5 0 0 0-5-5zM68 91V67.071l-6.465 6.465a5 5 0 1 1-7.07-7.071l14.997-14.998.022-.022A4.98 4.98 0 0 1 73 50a4.98 4.98 0 0 1 3.516 1.445l.022.022 14.998 14.998a5 5 0 1 1-7.071 7.07L78 67.072V91a5 5 0 0 1-10 0"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M35 50c0-8.284 6.716-15 15-15h45c8.284 0 15 6.716 15 15v45c0 8.284-6.716 15-15 15H50c-8.284 0-15-6.716-15-15zm15-5a5 5 0 0 0-5 5v45a5 5 0 0 0 5 5h45a5 5 0 0 0 5-5V50a5 5 0 0 0-5-5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
