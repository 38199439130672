import React, { useEffect } from 'react';
import SimpleProgressBar from '../ui/display/SimpleProgressBar/SimpleProgressBar';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  fetchValue: () => Promise<number>;
  onEnd?: () => void;
}

// Inspired by https://flowbite.com/docs/components/progress/
const ProgressBar: React.FC<Props> = ({ title, fetchValue, onEnd }) => {
  const { t, i18n } = useTranslation();
  const [value, setValue] = React.useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const nextValue = await fetchValue();
      setValue(nextValue);
      if (nextValue === 100) {
        onEnd?.();
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [fetchValue]);

  return (
    <div>
      <div className="flex justify-between mb-1 mt-3">
        <span className="text-base font-medium text-blue-700 dark:text-white">{title}</span>
        <span className="text-sm font-medium text-blue-700 dark:text-white">{`${value}%`}</span>
      </div>

      <SimpleProgressBar progressValue={value} />

      <div className="mt-5">{t('Emails are being sent in the background, you can safely back to another page.')}</div>
    </div>
  );
};

export default ProgressBar;
