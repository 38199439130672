import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { Conference, Announcement, Role, Track } from '../store/conference/types';
import {
  selectConference,
  selectCurrentConference,
  selectCurrentRole,
  selectCurrentTrack,
  selectCurrentUser,
  selectFormState,
  selectKeywordState,
  selectPaperStatusState,
  selectRoleRelations,
  selectSubmissionState,
  selectTrackRoles,
} from '../store/selectors';
import Loading from '../components/Loading/Loading';
import { can, SubmissionPermissionManager } from '../helpers/permissions';
import { Submission } from '../store/submission/types';
import { Button, Link } from '../components/ui';
import EditIcon from '../icons/Edit';
import SubmissionCard from '../components/SubmissionCard/SubmissionCard';
import { CategoryEnum, PaperStatus } from '../store/paper-status/types.d';
import { User } from '../store/user/types';
import useTheme from '@material-ui/core/styles/useTheme';
import styled, { ThemeProvider } from 'styled-components';
import history from '../store/history';
import { fillRoutePath } from '../helpers/path';
import { getRouteByName } from '../router/routes';
import { RowAction } from '../components/types';
import TrashCan from '../icons/TrashCan';
import DeleteSubmissionDialog from '../components/dialogs/DeleteSubmissionDialog/DeleteSubmissionDialog';
import AnnouncementSidePanel from '../components/AnnouncementSidePanel/AnnouncementSidePanel';
import { getFormsData } from '../helpers/form';
import { FormTypeEnum } from '../store/form/types.d';
import ViewNewIcon from '../icons/ViewNew';
import { Tooltip } from '@material-ui/core';
import { getPaperStatusName } from '../helpers/translations';
import { useTranslation } from 'react-i18next';
import EditSubmission from '../icons/EditSubmission';

interface Props {
  loading: boolean;
  conference: Conference | null;
  track: Track | null;
  role: Role | null;
  submissionsById: { [key: string]: Submission };
  user: User;
  paperStatusById: { [key: number]: PaperStatus };
  roleCanView: number[];
  roleById: { [key: string]: Role };
  announcementById: { [key: string]: Announcement };
}

const SubmissionsPage: React.FC<Props> = ({
  loading,
  conference,
  track,
  role,
  submissionsById,
  user,
  paperStatusById,
  roleCanView,
  roleById,
  announcementById,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  const getActions = (submission: Submission, canEdit: boolean, isPrimaryAuthor: boolean): RowAction[] => {
    const actions: RowAction[] = [];

    actions.push({
      id: '',
      label: '',
      callback: () => {},
      element: (
        <Link
          className="cursor-pointer text-sm flex items-center"
          onClick={() =>
            history.push(
              fillRoutePath(getRouteByName('RouteDetailSubmission').path, {
                id: submission.external_id.toString(),
              }),
            )
          }
        >
          <ViewNewIcon />
          <span className="ml-1">{t('View')}</span>
        </Link>
      ),
    });

    if (canEdit) {
      actions.push({
        id: '',
        label: '',
        callback: () => {},
        element: (
          <Link
            className="flex items-center cursor-pointer"
            onClick={(e) =>
              history.push(
                fillRoutePath(getRouteByName('RouteEditSubmission').path, { id: submission.external_id.toString() }),
              )
            }
          >
            <EditIcon color={theme.palette.primary.main} className="mr-1" />
            {t('Edit')}
          </Link>
        ),
      });
    } else if (!isPrimaryAuthor) {
      // Here we don't need to check whether the user is primary author but still need to run the of logic that determines edit permission.
      actions.push({
        id: '',
        label: '',
        callback: () => {},
        element: (
          <div className="flex items-center">
            <Tooltip title={t('Only primary authors can edit submissions.')} placement="top-end">
              <div
                className="flex items-center opacity-40 cursor-default"
                style={{ color: theme.palette.primary.main }}
              >
                <EditIcon color={theme.palette.primary.main} className="mr-1" />
                {t('Edit')}
              </div>
            </Tooltip>
          </div>
        ),
      });
    }

    if (isPrimaryAuthor)
      actions.push({
        id: '',
        label: '',
        callback: () => {},
        element: (
          <div
            className="flex items-center cursor-pointer"
            onClick={() =>
              setDialog(
                <DeleteSubmissionDialog open={true} handleClose={() => setDialog(undefined)} submission={submission} />,
              )
            }
            style={{ color: theme.palette.primary.main }}
          >
            <TrashCan stroke={theme.palette.primary.main} />
            <span className="ml-1">{t('Delete')}</span>
          </div>
        ),
      });

    return actions;
  };

  if (!conference || !track || !role || loading) {
    return <Loading />;
  }

  const announcement = announcementById[role.id];

  const authorRole = Object.values(roleById).find((role) => role.type == 'author');
  if (!authorRole) throw Error(t('Author role does not exist.'));

  /* Decide what to display */
  let content;
  if (Object.values(submissionsById).length > 0) {
    content = (
      <StyledCardsWrapper className="flex justify-start flex-wrap grow">
        {Object.values(submissionsById).map((submission, index) => {
          const isOwner = new SubmissionPermissionManager(submission).isPrimaryAuthor(user.person.id, role);
          const submissionFormsData = getFormsData(
            FormTypeEnum.Submission,
            ['read', 'write'],
            role.id,
            isOwner ? null : authorRole.id,
            user.person.id,
            submission,
          );
          const newRegister = { ...(submissionFormsData.answerable as Submission) };

          /* Set paper status accordingly */
          const paperStatus = paperStatusById[submission.paper_status_id];
          newRegister.paper_status =
            role.type == 'author' && paperStatus.category === CategoryEnum.In_Progress
              ? paperStatus.display_name ?? ''
              : getPaperStatusName(paperStatus.name, t);

          const canViewAuthors =
            roleCanView?.map((roleId) => roleById[roleId]).filter((role) => role.type === 'author').length > 0;
          const canEdit = new SubmissionPermissionManager(submission).canEdit(
            user,
            role,
            submissionFormsData.formsData.map((value) => value.form),
          );
          return (
            <div key={index} className="mb-6 w-80">
              <SubmissionCard
                submission={newRegister}
                actions={getActions(submission, canEdit, isOwner)}
                validationStatus={
                  canEdit && newRegister.validation_status !== 'Complete'
                    ? { title: t('Submission'), status: newRegister.validation_status }
                    : undefined
                }
                canViewAuthors={canViewAuthors}
              />
            </div>
          );
        })}
      </StyledCardsWrapper>
    );
  } else if (can('CAN_NEW_SUBMISSION')) {
    content = (
      <div className="h-full flex justify-center items-center">
        <StyledMessageWrapper className="flex justify-center items-center flex-col mb-6">
          <StyledIcon>
            <EditSubmission />
          </StyledIcon>
          <h2 className="text-xl font-bold w-max mt-3">{t('Get started with a new submission')}</h2>
          <p className="mt-3 mb-6 text-center">
            {t('Thank you for your interest, submit your paper to be part of the review process.')}
          </p>

          <div className="flex items-center mb-8">
            <StyledButton
              className="w-32 place-self-start"
              onClick={() => history.push(fillRoutePath(getRouteByName('RouteNewSubmission').path))}
            >
              <span className="px-14">{t('New submission')}</span>
            </StyledButton>
          </div>
        </StyledMessageWrapper>
      </div>
    );
  } else {
    content = (
      <div className="h-full flex justify-center items-center">
        <StyledMessageWrapper className="flex justify-center items-center flex-col mb-6">
          <StyledIcon>
            <EditSubmission />
          </StyledIcon>
          <h2 className="text-xl font-bold w-max mt-3">{t('Submission phase is closed')}</h2>
          <p className="mt-3 mb-6 text-center">
            {t(
              'New submissions are not accepted at the moment. As soon as opens, you will be able to submit your paper to be part of the review process.',
            )}
          </p>
        </StyledMessageWrapper>
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="h-full flex">
        <StyledContainer className="flex flex-col overflow-y-auto overflow-x-hidden mr-2">
          {Object.values(submissionsById).length > 0 && (
            <div className="flex items-center mb-6">
              <h2 className="font-bold text-xl mb-4 mr-5">{t('Your submissions')}</h2>
              {can('CAN_NEW_SUBMISSION') && (
                <Button
                  className="mb-3 w-48 place-self-center"
                  onClick={() => history.push(fillRoutePath(getRouteByName('RouteNewSubmission').path))}
                >
                  <span className="px-14">{t('New submission')}</span>
                </Button>
              )}
            </div>
          )}
          {content}
        </StyledContainer>
        {announcement && <AnnouncementSidePanel conference={conference} announcement={announcement} />}
      </div>
      {dialog}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading || selectKeywordState(state).loading || selectFormState(state).loading,
  conference: selectCurrentConference(state),
  track: selectCurrentTrack(state),
  role: selectCurrentRole(state),
  submissionsById: selectSubmissionState(state).submissionsById,
  user: selectCurrentUser(state),
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  roleCanView: selectRoleRelations(state, 'identity'),
  roleById: selectTrackRoles(state),
  announcementById: selectConference(state).announcementById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionsPage);

const StyledCardsWrapper = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;

const StyledContainer = styled.div`
  flex-grow: 1;

  .custom-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 25%));
  }

  .custom-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 20%));
  }
`;

const StyledMessageWrapper = styled.div`
  max-width: 30rem;

  h2 {
    font-size: 1.45rem;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
`;

const StyledIcon = styled.div`
  svg {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const StyledButton = styled(Button)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 5.5rem;
  padding-right: 5.5rem;
  font-size: 0.975rem;
`;
