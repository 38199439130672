import { put, takeLatest } from '@redux-saga/core/effects';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import permissionSlice from './slice';
import { getPermissionsResponse } from './types';
import { baseURL } from '../root-saga';

const getPermissionsAPICall = async (): Promise<Result<getPermissionsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<getPermissionsResponse>(`${baseURL}/api/permissions`, init);
};

function* getPermissionsSaga() {
  const result = (yield getPermissionsAPICall()) as Await<ReturnType<typeof getPermissionsAPICall>>;

  switch (result.type) {
    case 'ok':
      yield put(permissionSlice.actions['GET_PERMISSIONS:OK'](result.value));
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export default [takeLatest(permissionSlice.actions['GET_PERMISSIONS'].type, getPermissionsSaga)];
