import React from 'react';
import tw from 'twin.macro';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import PlusIcon from '../../../../icons/Plus';
import Tooltip from '@material-ui/core/Tooltip';

type AddButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  menuOpen?: boolean;
  className?: string;
  iconWidth?: string;
};

export const AddButton = ({
  label,
  menuOpen = false,
  disabled,
  className = 'text-sm font-normal ml-3',
  iconWidth,
  ...buttonProps
}: AddButtonProps): JSX.Element => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledButton className="flex items-center font-bold text-base ml-8 addButton" {...buttonProps}>
        <Tooltip title={!menuOpen ? '' : label} placement="right">
          <div className={`plus-icon-wrapper ${iconWidth ? iconWidth : ''}`}>
            <PlusIcon />
          </div>
        </Tooltip>

        {!menuOpen && <span className={className}>{label}</span>}
      </StyledButton>
    </ThemeProvider>
  );
};

const StyledButton = styled.button`
  span {
    color: rgb(0, 68, 240);
  }
  &.disabled {
    ${tw`pointer-events-none opacity-20`}
  }
  .small-icon {
    svg {
      width: 1.2rem;
    }
  }
`;
