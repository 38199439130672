import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#0044F0' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill={color}
        d="M11.457 1a3.72 3.72 0 0 0-2.5.924l-1.075.986a.464.464 0 1 0 .628.684l1.067-.98a2.8 2.8 0 0 1 1.872-.685 2.78 2.78 0 0 1 1.861.717 2.3 2.3 0 0 1 .761 1.668c.006.618-.25 1.22-.726 1.677l-1.863 1.72c-.267.246-.59.438-.949.562a2.88 2.88 0 0 1-2.218-.146 2.6 2.6 0 0 1-.847-.678.464.464 0 1 0-.722.585c.31.382.703.696 1.151.922s.942.36 1.448.393a3.8 3.8 0 0 0 1.49-.198 3.6 3.6 0 0 0 1.277-.758l1.866-1.723.005-.005A3.22 3.22 0 0 0 15 4.305a3.23 3.23 0 0 0-1.06-2.341A3.7 3.7 0 0 0 11.457 1"
      ></path>
      <path
        fill={color}
        d="M7.66 4.724a3.5 3.5 0 0 0-1.512.225 3.6 3.6 0 0 0-1.28.854L3.004 7.74l-.006.005A3.76 3.76 0 0 0 2 10.338c.008.963.38 1.888 1.04 2.574A3.52 3.52 0 0 0 5.544 14a3.51 3.51 0 0 0 2.52-1.043l1.07-1.11a.464.464 0 0 0-.67-.644l-1.06 1.101c-.5.5-1.165.774-1.852.767a2.6 2.6 0 0 1-1.841-.803 2.83 2.83 0 0 1-.781-1.938 2.83 2.83 0 0 1 .746-1.948l1.863-1.935c.269-.28.592-.495.945-.632a2.54 2.54 0 0 1 2.182.163c.332.188.622.45.851.767a.464.464 0 1 0 .754-.543A3.6 3.6 0 0 0 9.123 5.17a3.5 3.5 0 0 0-1.464-.446"
      ></path>
    </svg>
  );
}

export default Icon;
