import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useTheme from '@material-ui/core/styles/useTheme';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import arrayEquals from '../../helpers/arrayEquals';
import SearchIcon from '../../icons/Search';
import { Label } from '../../store/label/types';
import { LabelDefaultColors } from '../MyGridRedux/MyGridRedux';
import useStyles from './LabelPicker.styles';
import { useTranslation } from 'react-i18next';
import { DialogMode, LabelDialogFields } from '../dialogs/LabelDialog/LabelDialog';

export interface LabelPickerProps {
  allLabels: Label[];
  getCountsByLabelId: () => { [key: number]: number };
  onClickApply: (checked: number[], indeterminate: number[]) => void;
  closePopOver: () => void;
  numSelectedRows: number;
  openCreateLabelDialog: (data: LabelDialogFields, labelId: number | null, mode: DialogMode) => void;
  onManageClick: () => void;
  onClickLabel: (labelId: number) => void;
}

const LabelPicker: React.FC<LabelPickerProps> = ({
  allLabels,
  getCountsByLabelId,
  onClickApply,
  closePopOver,
  numSelectedRows,
  openCreateLabelDialog,
  onManageClick,
  onClickLabel,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const countsByLabelId = getCountsByLabelId();
  const pickedLabelIds = Object.keys(countsByLabelId).map(Number);
  const initChecked =
    numSelectedRows === 1
      ? pickedLabelIds
      : pickedLabelIds.filter((labelId) => countsByLabelId[labelId] === numSelectedRows);
  const initIndeterminate =
    numSelectedRows === 1 ? [] : pickedLabelIds.filter((labelId) => !initChecked.includes(labelId));

  const classes = useStyles();
  const [searchText, setSearchText] = React.useState('');
  const [checked, setChecked] = React.useState<number[]>(initChecked);
  const [indeterminate, setIndeterminate] = React.useState<number[]>(initIndeterminate);

  function getApplyItem(props = {}) {
    return (
      <ListItem
        button
        onClick={() => {
          onClickApply(checked, indeterminate);
          closePopOver();
        }}
        {...props}
      >
        {t('Apply')}
      </ListItem>
    );
  }

  const hasShowApply = () => {
    if (numSelectedRows === 1) return !arrayEquals(pickedLabelIds, checked);
    else return !arrayEquals(checked, initChecked) || !arrayEquals(indeterminate, initIndeterminate);
  };

  const showApply = hasShowApply();

  /* Checkbox list */
  const handleClick = (value: number, isIndeterminate: boolean) => {
    if (isIndeterminate) {
      // Remove from indeterminate
      const currentIndex = indeterminate.indexOf(value);
      if (currentIndex !== -1) {
        const newIndeterminate = [...indeterminate];
        newIndeterminate.splice(currentIndex, 1);
        setIndeterminate(newIndeterminate);
      }
      // Put it in checked
      if (!checked.includes(value)) setChecked([...checked, value]);
    } else {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    }
  };

  const splitStringAt = (s: string, pos: number) => {
    return [s.substring(0, pos), s.substring(pos, s.length)];
  };

  const formatString = (s: string, pos: number) => {
    const [s1, s2] = splitStringAt(s, pos);
    return (
      <React.Fragment>
        <b>{s1}</b>
        {s2}
      </React.Fragment>
    );
  };

  function filterLabels(labelString: string) {
    return allLabels
      .filter((label) => label.label.trim().toLowerCase().startsWith(labelString))
      .map((label, index) => {
        const isChecked = checked.indexOf(label.key) !== -1;
        const isIndeterminate = indeterminate.includes(label.key);
        return (
          <ListItem
            data-testid={`list_item_${index}`}
            button
            key={index}
            onClick={(_event) => {
              if (isIndeterminate || showApply) {
                handleClick(label.key, isIndeterminate);
              } else {
                onClickLabel(label.key);
                closePopOver();
              }
            }}
            selected={labelString !== '' && index === 0}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={isChecked}
                indeterminate={isIndeterminate}
                tabIndex={-1}
                disableRipple
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(label.key, isIndeterminate);
                }}
              />
            </ListItemIcon>
            <ListItemText primary={formatString(label.label, labelString.length)} />
          </ListItem>
        );
      });
  }

  function labelExists(labelString: string): boolean {
    const labels = new Set(allLabels.map((value) => value.label.trim().toLowerCase()));
    return labels.has(labelString);
  }

  const normSearchText = searchText.trim().toLowerCase();

  const filteredLabels = filterLabels(normSearchText);

  const textField = (
    <>
      <TextField
        id="search-labels"
        type="search"
        variant="standard"
        autoFocus={true}
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
          autoComplete: 'off',
        }}
        inputProps={{ maxLength: 20 }}
      />
    </>
  );

  let applyItem = getApplyItem();
  let footer;
  if (!normSearchText) {
    let items;
    if (showApply) {
      items = applyItem;
    } else {
      items = (
        <React.Fragment>
          <ListItem
            button
            onClick={() => {
              closePopOver();
              openCreateLabelDialog(
                {
                  name: '',
                  backgroundColor: LabelDefaultColors.backgroundColor,
                  textColor: LabelDefaultColors.textColor,
                },
                null,
                'create',
              );
            }}
          >
            {t('Create new')}
          </ListItem>
          <ListItem
            button
            onClick={() => {
              closePopOver();
              onManageClick();
            }}
          >
            {t('Manage labels')}
          </ListItem>
        </React.Fragment>
      );
    }
    footer = (
      <div className="pl-5">
        <hr />
        <List>{items}</List>
      </div>
    );
  } else if (!labelExists(normSearchText)) {
    if (filteredLabels.length === 0) applyItem = getApplyItem({ selected: true });
    footer = (
      <List>
        {showApply && applyItem}
        <ListItem
          data-testid="create_new"
          button
          onClick={(_event) => {
            closePopOver();
            openCreateLabelDialog(
              {
                name: searchText.trim(),
                backgroundColor: LabelDefaultColors.backgroundColor,
                textColor: LabelDefaultColors.textColor,
              },
              null,
              'create',
            );
          }}
        >
          "<b>{searchText.trim()}</b>" ({t('create new')})
        </ListItem>
      </List>
    );
  } else if (showApply) {
    footer = <List>{applyItem}</List>;
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledLabelDialog className={classes.root}>
        <p>{t('Label as') + ':'}</p>
        {textField}
        <List>{filteredLabels}</List>
        {footer}
      </StyledLabelDialog>
    </ThemeProvider>
  );
};

export default LabelPicker;

const StyledLabelDialog = styled.div`
  .MuiList-root {
    max-height: 340px;
    overflow: auto;
    overflow-x: hidden;
  }
  .MuiListItem-gutters {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiListItemIcon-root {
    min-width: 22px;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
  .MuiCheckbox-root {
    padding: 3px;
  }
  .MuiSvgIcon-root {
    fill: ${(props) => props.theme.palette.text.secondary};
    width: 0.875em;
    height: 0.875em;
  }
  .MuiListItemText-root {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .MuiList-padding {
    padding-top: 20px;
  }
  .MuiInputBase-input {
    padding: 6px 0 3px;
  }
  .MuiInputAdornment-root svg {
    margin: 8px 7px 0 0;
  }
  .MuiInput-underline::before {
    border-bottom: 1px solid ${(props) => props.theme.palette.secondary.dark};
  }
  .MuiInput-underline:hover::before {
    border-bottom: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;
