import React from 'react';
import { Card, Link, ShareLinkButton } from '../ui';
import EditIcon from '../../icons/Edit';
import TrashCan from '../../icons/TrashCan';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { Conference, Track } from '../../store/conference/types';

interface Props {
  conference: Conference;
  track: Track;
  handleDeleteTrack?: () => void;
  handleEditTrack: () => void;
}

const TrackCard: React.FC<Props> = ({ conference, track, handleDeleteTrack, handleEditTrack }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
    <Card>
      <h3 className="font-medium text-base">
        <Link className="flex items-center capitalize" onClick={undefined}>
          {track.name}
        </Link>
      </h3>

      <ul style={{ minHeight: '71px' }}>
        <li>
          <span className="font-bold">{t('Slug')}: </span>
          {track.slug}
        </li>
        <li>
          <span className="font-bold">{t('Chairs')}: </span>
          {track.chairs.map((chair) => `${chair.first_name} ${chair.last_name}`).join(', ')}
        </li>
      </ul>

      <div className="flex justify-between w-full left-0 px-5 text-sm absolute bottom-4">
        <div className="flex items-center">
          <Link className="flex items-center cursor-pointer" onClick={handleEditTrack}>
            <EditIcon color={theme.palette.primary.main} className="mr-1" />
            {t('Edit')}
          </Link>

          <ShareLinkButton
            conferenceSlug={conference.slug}
            trackSlug={track?.slug ?? ''}
            variant="text"
            className="ml-2"
          />
        </div>

        {handleDeleteTrack && (
          <div className="cursor-pointer w-4 flex justify-center flex-col" onClick={handleDeleteTrack}>
            <TrashCan stroke={theme.palette.primary.main} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default TrackCard;
