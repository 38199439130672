import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import emailSlice from './slice';
import { baseURL } from '../root-saga';
import {
  createTemplateResponse,
  EmailRenderDTO,
  EmailSendDTO,
  EmailTemplate,
  getEmailCountResponse,
  getEmailRenderResponse,
  getEmailsResponse,
  sendEmailResponse,
} from './types';
import errorSlice from '../error/slice';
import React from 'react';
import i18next from '../../i18n';

const sendEmail = async (data: EmailSendDTO): Promise<Result<sendEmailResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<sendEmailResponse>(`${baseURL}/api/emails`, init);
};

function* sendEmailSaga(action: PayloadAction<EmailSendDTO>) {
  const result = (yield sendEmail(action.payload)) as Await<ReturnType<typeof sendEmail>>;

  switch (result.type) {
    case 'ok':
      const { dry_run } = action.payload;
      const { rows } = result.value.data;
      if (dry_run) {
        yield put(errorSlice.actions['CLEAR:VALIDATION_ERRORS']());
        yield put(emailSlice.actions['GET:PREVIEW:OK']({ preview: rows, email: action.payload }));
      } else {
        yield put(emailSlice.actions['REMOVE:PREVIEW']());
        yield put(emailSlice.actions['SEND:OK'](result.value));
      }
      break;
    case 'validation-error':
      yield put(errorSlice.actions['SET:VALIDATION_ERRORS'](Object.values(result.value.validation)));
      yield put(emailSlice.actions['SEND:KO']());
      break;
    default:
      break;
  }
  return;
}

export const getEmailRender = async (
  data: EmailRenderDTO,
): Promise<Result<getEmailRenderResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<getEmailRenderResponse>(`${baseURL}/api/emails/render`, init);
};

export const getEmailCount = async (token: string): Promise<Result<getEmailCountResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<getEmailCountResponse>(`${baseURL}/api/emails/${token}/count`, init);
};

export const getEmails = async (
  pending: React.MutableRefObject<string>,
): Promise<Result<getEmailsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    accept: 'application/json',
    auth: true,
    role: true,
  };
  return apiCall<getEmailsResponse>(`${baseURL}/odata/Emails?$count=true&` + pending.current, init);
};

const createEmailTemplate = async (
  data: EmailTemplate,
): Promise<Result<createTemplateResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<createTemplateResponse>(`${baseURL}/api/emails/templates`, init);
};

function* createEmailTemplateSaga(action: PayloadAction<EmailTemplate>) {
  const result = (yield createEmailTemplate(action.payload)) as Await<ReturnType<typeof createEmailTemplate>>;

  switch (result.type) {
    case 'ok':
      yield put(emailSlice.actions['TEMPLATES:CREATE:OK'](result.value));
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Template created.'), severity: 'success' }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const updateEmailTemplate = async (
  data: EmailTemplate,
): Promise<Result<createTemplateResponse, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<createTemplateResponse>(`${baseURL}/api/emails/templates/${data.id}`, init);
};

function* updateEmailTemplateSaga(action: PayloadAction<EmailTemplate>) {
  const result = (yield updateEmailTemplate(action.payload)) as Await<ReturnType<typeof updateEmailTemplate>>;

  switch (result.type) {
    case 'ok':
      yield put(emailSlice.actions['TEMPLATES:CREATE:OK'](result.value));
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Template updated.'), severity: 'success' }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const deleteTemplate = async (id: number): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };
  return apiCall<{ data: any }>(`${baseURL}/api/emails/templates/${id}`, init);
};

function* deleteTemplateSaga(action: PayloadAction<number>) {
  const result = (yield deleteTemplate(action.payload)) as Await<ReturnType<typeof deleteTemplate>>;

  switch (result.type) {
    case 'ok':
      yield put(emailSlice.actions['TEMPLATES:DELETE:OK'](action.payload));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('Template deleted successfully.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      yield put(emailSlice.actions['TEMPLATES:DELETE:KO']());
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(emailSlice.actions['SEND'], sendEmailSaga),
  takeLatest(emailSlice.actions['TEMPLATES:CREATE'], createEmailTemplateSaga),
  takeLatest(emailSlice.actions['TEMPLATES:UPDATE'], updateEmailTemplateSaga),
  takeLatest(emailSlice.actions['TEMPLATES:DELETE'], deleteTemplateSaga),
];
