import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#D3E1FF' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="121" height="121" fill="none" viewBox="0 0 121 121">
      <path
        fill={color}
        fillRule="evenodd"
        d="M100.402 5.279a15.6 15.6 0 0 0-11.035 4.57l-47.5 47.5a5 5 0 0 0-1.315 2.324l-5 20a5 5 0 0 0 6.063 6.063l20-5a5 5 0 0 0 2.323-1.315l47.5-47.5a15.606 15.606 0 0 0-11.036-26.642M96.438 16.92a5.606 5.606 0 1 1 7.929 7.929l-46.52 46.52-10.573 2.643 2.643-10.572z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        d="M20.402 15.885a15 15 0 0 0-15 15v70a15 15 0 0 0 15 15h70a15 15 0 0 0 15-15v-35a5 5 0 1 0-10 0v35a5 5 0 0 1-5 5h-70a5.003 5.003 0 0 1-5-5v-70a5 5 0 0 1 5-5h35a5 5 0 1 0 0-10z"
      ></path>
    </svg>
  );
}

export default Icon;
