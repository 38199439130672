import React, { useState } from 'react';
import FileUploadUpdater from '../../components/FileUploadUpdater/FileUploadUpdater';
import { postAssignmentsBulk } from '../../store/review/sagas';
import { AppState } from '../../store/state';
import {
  selectInfo,
  selectAssignmentState,
  selectSubmissionState,
  selectExistRolesHandleReviewers,
} from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, SelectRadioButton } from '../../components/ui';
import Import from '../../icons/Import';
import { downloadFile, getHref } from '../../helpers/download';

type TemplateName = 'assignments-of-submission' | 'assignments-of-reviewer';

interface Props {
  loading: boolean;
  existRolesHandleReviewers: boolean;
}

const AssignmentsBulkImportPage: React.FC<Props> = ({ loading, existRolesHandleReviewers }) => {
  const { t } = useTranslation();
  const [templateName, setTemplateName] = useState<TemplateName | undefined>(
    existRolesHandleReviewers ? undefined : 'assignments-of-submission',
  );
  const [startImport, setStartImport] = useState<boolean>(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col h-full">
      {startImport && templateName ? (
        <StyledUploaderWrapper className="mt-2 mb-2 flex flex-col h-full">
          <FileUploadUpdater
            doPreview={(file) => {
              return postAssignmentsBulk({ file, dry_run: true, templateName });
            }}
            doSave={(file) => postAssignmentsBulk({ file, dry_run: false, templateName })}
            templateName={templateName}
            onCancelHandler={() => setStartImport(false)}
            displayOpen={true}
          />
        </StyledUploaderWrapper>
      ) : (
        <div className="h-full flex justify-center items-center">
          <StyledMessageWrapper className="flex justify-center items-center flex-col mb-6">
            <StyledIcon>
              <Import />
            </StyledIcon>
            <h2 className="text-xl font-bold w-max mt-3">{t('Effortlessly import your data in bulk')}</h2>
            <p className="mt-3 mb-6 text-center">
              {t(
                'Just download our template, fill it in with your data, and upload it here. We’ll take care of the rest, ensuring everything is perfectly organised and ready for use.',
              )}
            </p>

            <div className="mb-6">
              {existRolesHandleReviewers ? (
                <SelectRadioButton
                  required
                  options={[
                    { id: 'assignments-of-submission', label: t('Import assignments of submission') },
                    { id: 'assignments-of-reviewer', label: t('Import assignments of reviewer') },
                  ]}
                  value={templateName}
                  onChange={(value) => setTemplateName(value as TemplateName)}
                  className="flex flex-col mt-1 gap-y-1"
                />
              ) : undefined}
            </div>

            {templateName && (
              <div className="flex items-center mb-8">
                <StyledButton
                  className="w-32 place-self-start mr-6"
                  variant="outlined"
                  onClick={() => downloadFile(getHref(`/web/templates/${templateName}`))}
                >
                  <span className="px-14">{t('Download template')}</span>
                </StyledButton>
                <StyledButton className="w-32 place-self-start" onClick={() => setStartImport(true)}>
                  <span className="px-14">{t('Start import')}</span>
                </StyledButton>
              </div>
            )}
          </StyledMessageWrapper>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading || selectSubmissionState(state).loading || selectAssignmentState(state).loading,
  existRolesHandleReviewers: selectExistRolesHandleReviewers(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

const StyledUploaderWrapper = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div {
      input,
      a {
        font-size: 0.875rem;
      }
    }

    .buttonWrapper {
      margin: 2rem 0;
    }
  }
`;

const StyledMessageWrapper = styled.div`
  max-width: 32rem;

  h2 {
    font-size: 1.45rem;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .fileInputWrapper {
    margin-bottom: 0;
  }
  .radiobutton-wrapper {
    label {
      margin-right: 0;
    }
  }
`;

const StyledIcon = styled.div`
  svg {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const StyledButton = styled(Button)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 5.5rem;
  padding-right: 5.5rem;
  font-size: 0.975rem;
`;

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsBulkImportPage);
