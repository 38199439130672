import React from 'react';
import styled from 'styled-components';
import ShareLink from '../../../../icons/ShareLink';
import Done from '../../icons/Done';
import { useTranslation } from 'react-i18next';
import { getRouteByName } from '../../../../router/routes';
import { generatePath } from 'react-router';
import { addSearchParams } from '../../../../helpers/download';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'text' | 'solid';
  conferenceSlug: string;
  trackSlug: string;
};

export const ShareLinkButton = ({ variant = 'solid', className, conferenceSlug, trackSlug }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [response, setResponse] = React.useState<boolean>(false);

  return (
    <StyledShareLinkButton
      className={`shareLinkButton flex items-center text-sm py-0.5 px-1 ${className} ${variant} ${
        response ? 'response' : 'cursor-pointer'
      }`}
      type="button"
      onClick={() => {
        const conferencePath = generatePath(getRouteByName('RouteConferenceHome').path, {
          conference: conferenceSlug,
          track: trackSlug,
        });
        const conferenceUrl = new URL(window.location.origin + conferencePath);
        const shareUrlForAuthors = addSearchParams(conferenceUrl, { role: 'author' });
        navigator.clipboard.writeText(shareUrlForAuthors.toString());

        setResponse(true);
        setTimeout(() => setResponse(false), 1500);
      }}
    >
      {response ? <Done color="#0aab25" /> : <ShareLink />}
      <span>{response ? t('Link copied') : t('Share for authors')}</span>
    </StyledShareLinkButton>
  );
};

const StyledShareLinkButton = styled.button`
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  color: ${(props) => props.theme.palette.primary.main};

  svg {
    margin-right: 1px;
  }

  &.solid {
    background-color: #edf3ff;
    border: 1px solid #d4dff5;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    border-radius: 0.2rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }
  &.response {
    background-color: #def2da;
    border: 1px solid #d1e5cd;
    color: #0aab25;
  }
  &.text {
    background-color: transparent !important;
    border: none !important;
    padding-left: 0;
    padding-right: 0;
  }
`;
